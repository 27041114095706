/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */
@import '~@ionic/angular/css/ionic.bundle.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
    font-family: 'Roboto', sans-serif;
}

.alert-input .alert-wrapper {
    position: absolute;
    top: 10%;
}

.error-message {
    color: var(--ion-color-vh-red);
    font-size: 0.8rem;
    padding-bottom: 8px;
    text-align: left;
    //position: absolute;
    z-index: 99;
}

.no-data {
    font-size: 0.8rem;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

::ng-deep .ant-table-header{
    padding-right: 0;
}
sup {
    position: absolute !important;
    top: 18px !important;
    color: var(--ion-color-vh-red);
    font-size: 100% !important;
}

pre{
    white-space: pre-line;font-family: 'Roboto' !important;margin: 0;
    font-size: 0.85rem  !important;
}

.validation-errors {
    font-size: 0.6rem;
    color: var(--ion-color-vh-red);
}

.center-all-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 0.9rem;
}

.ant-pagination-item-active {
    border-color: var(--ion-color-vh-green) !important;
    a {
        color: var(--ion-color-vh-green) !important;
    }
}

.left-all-content {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.right-all-content {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.ant-badge-count {
    background: var(--ion-color-vh-green) !important;
}


/* CSS- zorro component*/

.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--ion-color-vh-green);
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-selected,
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: var(--ion-color-vh-green) !important;
}

.ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    color: var(--ion-color-vh-black) !important;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-active>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-active>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-selected>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-selected>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    // background: linear-gradient(to right, var(--ion-color-vh-green), var(--ion-color-vh-green));
    color: var(--ion-color-vh-green) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
    color: var(--ion-color-vh-green) !important;
}

.ant-tabs-ink-bar {
    background: var(--ion-color-vh-green) !important;
}

.ant-checkbox-checked::after {
    background-color: var(--ion-color-vh-green) !important;
    border: 1px solid var(--ion-color-vh-green);
    color: var(--ion-color-vh-white) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--ion-color-vh-green) !important;
    border-color: var(--ion-color-vh-green) !important;
}

.ant-input:focus.ant-input-focused,
.ant-input:focus {
    border-color: var(--ion-color-vh-green) !important;
    box-shadow: none;
}

.ant-select:focus {
    box-shadow: none;
}

.ant-list-item-meta-description {
    font-size: 0.9rem !important;
    color: black !important;
    font-weight: bold !important;
}

.ant-list-item-meta-title {
    font-size: 0.8rem !important;
    color: gray !important;
    margin: 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--ion-color-vh-green) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--ion-color-vh-green);
}

.ant-picker-today-btn {
    color: var(--ion-color-vh-green);
}

.ant-picker-focused {
    border-color: none !important;
}

.ant-picker-focused,
.ant-picker:hover {
    border-color: var(--ion-color-vh-green);
}

.ant-picker-input>input:focus {
    border-color: var(--ion-color-vh-green);
}

.ant-btn-primary {
    background: var(--ion-color-vh-green) !important;
    border-color: var(--ion-color-vh-green) !important;
}

.ant-modal {
    width: 390px;
}

.ant-modal-header {
    padding: 10px 15px;
}

.ant-modal-body {
    padding: 15px;
}

.ant-modal-footer {
    padding: 10px 15px;
}

.item-inner {
    padding-right: 0 !important;
}

.ant-btn {
    height: auto;
}

.ant-modal-body {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.ant-modal-body::-webkit-scrollbar {
    width: 0px !important;
    /* For Chrome, Safari, and Opera */
}

.ant-layout,
.ant-modal {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.ant-layout::-webkit-scrollbar,
.ant-modal::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
}

.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
    border-color: var(--ion-color-vh-green) !important;
    color: var(--ion-color-vh-green) !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--ion-color-vh-green) !important;
}

.ant-card-body {
    padding: 0 !important;
}

.ant-card-meta-title {
    margin: 0 !important;
    font-size: 0.9rem !important;
    color: var(--ion-color-vh-green) !important;
    line-height: 1.2em;
    white-space: normal;
    height: 2.4em;
    text-overflow: ellipsis;
}

.ant-card-meta-description {
    color: var(--ion-color-vh-black) !important;
}

.ant-radio-checked {
    color: var(--ion-color-vh-green) !important;
}

.ant-input:hover {
    border-color: #c8c7cc !important;
}

.nz-hover-border:focus,
.ant-pagination-item:focus {
    color: var(--ion-color-vh-black) !important;
    border-color: var(--ion-color-vh-green) !important;
}

.nz-hover-border:hover,
.ant-pagination-item:hover {
    color: var(--ion-color-vh-black) !important;
    border-color: var(--ion-color-vh-green) !important;
}

.nz-hover-border-green:focus,
.nz-hover-border-green:hover {
    color: var(--ion-color-vh-white) !important;
    background: var(--ion-color-vh-green) !important;
    border-color: var(--ion-color-vh-green) !important;
}

.ant-card-meta-detail {
    padding: 5px !important;
}

.ant-divider-horizontal {
    margin: 5px 0 15px 0;
}

.ant-cascader-picker {
    width: 100% !important;
}

.ant-drawer-body {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.ant-drawer-body::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
}

.ant-radio-checked+label::after {
    background: var(--ion-color-vh-green) !important;
    color: var(--ion-color-vh-green) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--ion-color-vh-green);
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--ion-color-vh-green);
}

.ant-input-number:hover {
    border-color: var(--ion-color-vh-green) !important;
}

.ant-card-bordered .ant-card-cover {
    display: flex;
    justify-content: center;
}

.ant-typography-edit {
    color: #373737;
    font-size: 16px;
}

.ant-typography {
    color: var(--ion-color-vh-green) !important;
    margin-bottom: 0 !important;
}

.ant-radio-inner::after {
    background-color: var(--ion-color-vh-green) !important;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse {
    border: none !important;
}

.ant-collapse-content {
    border-top: none !important;
}

input {
    width: 100px;
    margin-left: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
}

button {
    border-radius: 5px !important;
}

// .ant-tabs-tabpane {
//   display: flex !important;
//   justify-content: center !important;
// }
.ant-switch {
    border-radius: 100px !important;
}

.ant-switch-checked {
    background-color: var(--ion-color-vh-green) !important;
}

.ant-drawer-title {
    font-size: 1.2rem !important;
}

.ant-form label {
    font-size: 0.9rem !important;
}

.ant-cascader-picker-label {
    font-size: 0.9rem !important;
}

.ant-picker-input>input {
    font-size: 0.9rem !important;
}

.ant-input-group .ant-input {
    font-size: 0.9rem !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 0.9rem !important;
}

.ant-select-item-option-content {
    font-size: 0.9rem !important;
    white-space: normal;
}

.ant-cascader-input.ant-input {
    font-size: 0.9rem !important;
}

.ant-cascader-menu-item {
    font-size: 0.9rem !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 0.9rem !important;
}

.date-picker-cus {
    border-radius: 5px !important;
}

.ant-input-affix-wrapper>input.ant-input {
    font-size: 0.9rem !important;
}


/* ----------------------------------------- */

html {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

html::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
}

// .ant-table-body {
//   scrollbar-width: none; /* For Firefox */
//   -ms-overflow-style: none; /* For Internet Explorer and Edge */
// }
// .ant-table-body::-webkit-scrollbar {
//   width: 0px; /* For Chrome, Safari, and Opera */
// }
.ant-modal-wrap {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.ant-modal-wrap {
    overflow: hidden;
}

.ant-modal-wrap::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
}
app-sales-return-supplier-invoice,
app-sales-return-retail-invoice,
app-sales-return-customer-invoice,
app-policy,
app-exchange-order-payment-card,
app-exchange-order-voucher,
app-sales-order-voucher,
app-sales-order-payment-card,
app-sales-order,
app-create-receipts,
app-create-expenses,
app-sales-payment-card,
app-sales-voucher,
app-customer-online-list,
app-destroy-product,
app-balance-stock,
app-manage-lots,
app-destroy-lot-product,
app-print-one-col-lot,
app-print-two-col-lot,
app-print-three-col-lot,
app-print-four-col-lot,
app-print-five-col-lot,
app-label-lots,
app-label-products,
app-combo-category,
app-sales-payment-card,
app-sales-voucher,
app-to-cards,
app-exchange-card-invoice,
app-exchange-voucher-invoice,
app-sales-program,
app-exchange-promotions,
app-analytics,
app-sell-card,
app-releases-detail,
app-releases,
app-list-card,
app-card-goods,
app-to-vouchers,
app-exchange-points,
app-to-coupons,
app-list-code,
app-design-cards,
app-design-vouchers,
app-design-coupons,
app-design,
app-product,
app-coupon,
app-released,
app-publish-coupons,
app-list-coupons,
app-coupons,
app-point-history,
app-reward-points,
app-by-bill,
app-by-product,
app-manage-point,
app-employee-group,
app-employee-list,
app-customer-list,
app-detail-group,
app-edit-group,
app-customer-group,
app-print-barcode,
app-invoice-discount,
app-discount-employee,
app-show-report,
app-list-discount,
app-discount,
app-sales-discount,
app-print-five-col,
app-print-four-col,
app-print-one-col,
app-print-three-col,
app-print-two-col,
app-price-comparison,
app-sendo-printer,
app-control,
app-profit-product,
app-auto-sale,
app-report,
app-order,
app-product-profit,
app-return-control,
app-payment,
app-payment-detail {
    height: 100% !important;
}

app-sync-data,
app-control-diary {
    height: 100% !important;
}

app-control-money {
    height: 100% !important;
}

app-control-exel {
    height: 100% !important;
}

app-control-automation {
    height: 100% !important;
}

app-edit,
app-add {
    height: 100% !important;
}

app-detail {
    height: 100% !important;
}

app-sale {
    height: 100% !important;
}

app-invoices {
    height: 100% !important;
}

app-purchase-return-invoice,
app-sales-return-invoice,
app-report-inventory {
    height: 100% !important;
}

app-detail-purchase-return,
app-detail-sale-return,
app-edit-sales-return {
    height: 100% !important;
}

app-edit-purchase-return {
    height: 100% !important;
}

app-design-barcode,
app-combo,
app-products,
app-partner,
app-employee,
app-add-employee,
app-detail-employee,
app-customer,
app-add-customer,
app-detail-customer,
app-supplier,
app-add-supplier,
app-detail-supplier,
app-stock {
    height: 100% !important;
}

app-products-list,
app-add-product,
app-edit-product,
app-create-data-products,
app-create-data-customers,
app-create-data-supplier {
    height: 100% !important;
}

app-product-category,
app-detail-category-products,
app-services-list,
app-add-service,
app-detail-service,
app-create-data-service,
app-service-category,
app-detail-category-services {
    height: 100% !important;
}

app-cashbook,
app-inventory,
app-cashflow {
    height: 100% !important;
}

app-detail-three-col,
app-receipts,
app-expenses,
app-wallet,
app-supplier-liability,
app-detail-supplier-liability,
app-detail-invoice,
app-beginning-supplier-liability,
app-beginning-customer-debt,
app-customer-debt,
app-detail-customer-debt,
app-note,
app-cart,
app-detail {
    height: 100% !important;
}

app-warranty,
app-invoices,
app-schedule,
app-receipt-quote {
    height: 100% !important;
}

app-history-payment,
app-history {
    height: 100% !important;
}

app-detail-history,
app-display,
app-sales-customer,
app-sales-combo,
app-reports,
app-sales-product,
app-detail-product,
app-sales-employee,
app-sales-category,
app-detail-category,
app-sales-service,
app-out-of-stock,
app-sales-profit,
app-branches-comparison,
app-best-selling-categories,
app-best-selling-products,
app-sales-warranty,
app-input-output-inventory,
app-input-output-return,
app-delivery,
app-carrier-partner,
app-shipping-bill,
app-delivery-ghn,
app-printer,
app-tax,
app-promotions,
app-create-bill-retail,
app-configuration,
app-detail-bill,
app-create-bill-customer,
app-reuse-bill-retail,
app-reuse-bill-customer,
app-discount-money,
app-discount-rate,
app-overview,
app-delivery-ghtk,
app-create-bill-retail-ghtk,
app-create-bill-customer-ghtk,
app-detail-bill-ghtk,
app-chat,
app-delivery-ahamove,
app-create-bill-customer-ahamove,
app-create-bill-retail-ahamove,
app-detail-bill-ahamove,
app-store-info,
app-detail-receipt-expenses,
app-detail-invoice,
app-detail-bill-warranty,
app-video,
app-warehouse,
app-diary,
app-purchase,
app-add-purchase,
app-supplier-return,
app-add-supplier-return,
app-customer-return,
app-add-customer-return,
app-opening-stock,
app-add-opening-stock,
app-detail-bill-import,
app-detail-bill-export,
app-detail-bill-warranty,
app-create-opening-data,
app-warranty-invoice,
app-edit-warranty-invoice,
app-purchase,
app-carrying-trade,
app-receive-the-goods,
app-report-carrying-trade,
app-receive-the-goods,
app-transfers,
app-receiving-money,
app-branch,
app-detail-bill-delivery-receive-goods,
app-detail-bill-debt,
app-detail-bill-supplier-return,
app-detail-opening-stock,
app-ecommerce,
app-shopee,
app-affiliate-product,
app-settings,
app-order-status,
app-update-selling-price,
app-update-stock,
app-return-refund,
app-sendo,
app-lazada,
app-tiki,
app-cashflow-management,
app-money-control,
app-goods-control,
app-customer-return,
app-delivery-goods,
app-input,
app-receive-goods,
app-return-supplier,
app-sales,
app-warranty,
app-transfer-receive,
app-control-export-goods,
app-issue-invoice,
app-product-on-shop,
app-by-product-app,
app-by-product-sendo,
app-issue-delivery-goods,
app-issue-receive-goods,
app-by-product-lazada,
app-by-product-tiki,
app-by-product-shopee,
app-print,
app-temporary-sales-invoice,
app-edit-temporary,
app-lazada-printer,
app-temporary-purchase-invoice,
app-edit-temporary-purchase,
app-socials-channels,
app-facebook,
app-socials-connect,
app-facebook-conversation,
app-lazada-printer,
app-support,
app-delivery-vnpost,
app-detail-bill-vnpost,
app-create-bill-retail-vnpost,
app-create-bill-customer-vnpost,
app-delivery-viettel,
app-create-bill-customer-viettel,
app-create-bill-retail-viettel,
app-detail-bill-viettel {
    height: 100% !important;
}

app-report-voucher,
app-detail-voucher,
app-create-bill,
app-buy-voucher,
app-list-code-voucher,
app-release-detail,
app-releases-voucher,
app-active-voucher,
app-add-voucher,
app-list-voucher,
app-voucher,
app-led-price {
    height: 100% !important;
}

.money-right {
    text-align: right;
    color: var(--ion-color-vh-green);
    font-weight: bold;
}

.total-item {
    font-size: 0.8rem;
}

.add-button {
    display: flex;
    justify-content: flex-end;
    // position: absolute;
    // right: 24px;
    button {
        color: #fff;
        background: var(--ion-color-vh-green) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font-size: 0.9rem;
    }
}

.money-sub {
    align-items: center;
    justify-content: flex-end;
    display: flex;
}

.right-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.center-div {
    display: flex;
    align-items: center;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.nz-no-padding {
    padding: 0 !important;
}

.nz-no-margin {
    margin: 0 !important;
}

.nz-no-border {
    border: 0 !important;
}

.large-size {
    font-size: 1rem;
}

.small-size {
    font-size: 0.8rem;
}

.medium-size,
.ant-table-content {
    font-size: 0.9rem;
}

.title-size {
    font-size: 1.2rem;
}

// ----custom modal----
.ant-modal {
    height: 700px;
    overflow: hidden;
}

.ant-modal-body {
    overflow-y: scroll;
}

.ant-picker-clear {
    display: none !important;
}

nz-select {
    font-size: 0.8rem;
}

.ant-picker-input {
    input {
        font-size: 0.9rem;
    }
}

.ant-tabs-tab,
.ant-table,
.ant-table-tbody {
    font-size: 0.9rem;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
    color: var(--ion-color-vh-green);
}


/**Custom scrollbar*/

.ant-table-body::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
    background-color: #f5f5f5;
}

.ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#cccccc), to(#cccccc), color-stop(0.6, #cccccc));
}

.ant-table-body::-webkit-scrollbar-thumb:horizontal {
    height: 8px;
}

.ant-table-body::-webkit-scrollbar:horizontal {
    height: 8px !important;
    background-color: #f5f5f5;
}

.text-end {
    text-align: end !important;
}

.ant-form-item textarea.ant-input {
    font-size: 0.9rem !important;
}

.ant-picker {
    padding: 4px 11px !important;
    border-radius: 4px;
}

.btn-white {
    font-size: 0.9rem;
    width: 100% !important;
    border-radius: 5px;
    color: var(--ion-color-vh-black);
}

.btn-white:hover {
    color: var(--ion-color-vh-black);
    border-color: var(--ion-color-vh-green);
}

.btn-green {
    font-size: 0.9rem;
    width: 100% !important;
    border-radius: 5px;
    color: var(--ion-color-vh-white) !important;
    background-color: var(--ion-color-vh-green) !important;
    ion-icon {
        margin-right: 5px;
        font-size: 1.1rem;
    }
}

nz-cascader {
    border-radius: 6px;
    .ant-cascader-input,
    .ant-cascader-picker {
        border-radius: 6px;
    }
}

.sider-icon {
    .anticon {
        font-size: 1.2rem !important;
    }
}

.ant-menu-vertical.ant-menu-sub {
    max-height: 70vh;
}

.ant-tabs-tab {
    max-width: 300px;
    &-btn {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

//căn chỉnh giữa ngang doc
.text-align-self {
    align-self: center;
    text-align: center;
}

//không tràn chữ khi hiển thị quá 2 hàng, hiển thị 3 chấm
.max-width-two-line {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    margin: 0;
}

.max-width-overflow {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.row-back {
    display: inline-block;
}

.ant-drawer-close {
    top: 10px;
}

.ant-input-affix-wrapper {
    border-radius: 4px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
    border-color: var(--ion-color-vh-green) !important;
    box-shadow: none;
}

nz-select-top-control {
    height: 33px !important;
}

//ecommerce
.model-link-product-ecommerce {
    .ant-modal-close-x {
        width: 40px !important;
        height: 40px !important;
        line-height: 40px !important;
    }
}

// pos
.pos {
    .pos-header-tabs {
        .ant-tabs-nav {
            margin: 0 !important;
        }
        .ant-tabs-nav-add:hover {
            color: var(--ion-color-vh-green) !important;
        }
    }
    .ant-input-affix-wrapper {
        padding: 0;
    }
}


/* Hide scrollbar*/

.hideScrollbar {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.hideScrollbar::-webkit-scrollbar {
    width: 0px;
    /* For Chrome, Safari, and Opera */
}

.ant-modal-confirm-btns {
    button:nth-child(1):hover {
        color: var(--ion-color-vh-green) !important;
        border-color: var(--ion-color-vh-green) !important;
    }
}

.orginial-disabled {
    background: white !important;
    nz-select-top-control {
        background: white !important;
        color: var(--ion-color-vh-black) !important;
    }
    input {
        background: white !important;
        color: var(--ion-color-vh-black) !important;
    }
}

//color-sprin
.ant-spin-dot-item {
    background-color: var(--ion-color-vh-green) !important;
    z-index: 1000;
}

.ant-spin-text {
    color: var(--ion-color-vh-green) !important;
    z-index: 1000;
}

.color-green {
    color: var(--ion-color-vh-green);
}

.color-red {
    color: var(--ion-color-vh-red);
}

.color-orange {
    color: var(--ion-color-vh-orange);
}

.product-table-modal-ecommerce {
    .ant-table-thead {
        display: none;
    }
}

.width-fit-content {
    width: fit-content !important;
}

.btn-confirm {
    background: white !important;
    color: var(--ion-color-vh-green) !important;
    border: 1px solid var(--ion-color-vh-green) !important;
    transition: 0.4s;
    width: fit-content;
    &:hover {
        background: var(--ion-color-vh-green) !important;
        color: var(--ion-color-vh-white) !important;
        transition: 0.4s;
    }
}
