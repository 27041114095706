/** Toast Ctrl*/

.alert-toast {
    --background: var(--ion-color-vh-red);
    --color: var(--ion-color-vh-white);
}

.success-toast {
    --background: var(--ion-color-vh-green);
    --color: var(--ion-color-vh-white);
}


/** Toast Ctrl*/


/** Loading Ctrl */

.current-loading {
    --spinner-color: var(--ion-color-vh-green);
    --backdrop-opacity: 0.1;
}

.transparent-loading {
    --spinner-color: var(--ion-color-vh-green);
    --background: transparent;
    &>.sc-ion-loading-md {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}


/** Loading Ctrl */


/**Action sheet Ctrl */

.action-sheet-red {
    --button-color: var(--ion-color-vh-red);
    --color: var(--ion-color-vh-red);
    --button-color-hover: var(--ion-color-vh-red);
    --button-color-selected: var(--ion-color-vh-red);
    --button-color-activated: var(--ion-color-vh-red);
}

.action-sheet-current {
    --button-color: var(--ion-color-vh-green);
    --color: var(--ion-color-vh-green);
    --button-color-hover: var(--ion-color-vh-green);
    --button-color-selected: var(--ion-color-vh-green);
    --button-color-activated: var(--ion-color-vh-green);
}

.action-sheet-black {
    --button-color: var(--ion-color-vh-black);
    --color: var(--ion-color-vh-black);
    --button-color-hover: var(--ion-color-vh-black);
    --button-color-selected: var(--ion-color-vh-black);
    --button-color-activated: var(--ion-color-vh-black);
}


/**Action sheet Ctrl */


/** Modal controller */

.modal-transparent .modal-wrapper {
    --background: transparent;
}
.modal-print .modal-wrapper{
    @media only screen and (min-width: 768px) {
        min-width: 800px !important;
    }
}
.modal-print-print_size_a4 .modal-wrapper {
    @media only screen and (min-width: 768px) {
        min-width: 800px !important;
    }
}
.modal-print-print_size_a5 .modal-wrapper {
    @media only screen and (min-width: 768px) {
        min-width: 800px !important;
    }
}
.modal-print-print_size_k80_4c .modal-wrapper,
.modal-print-print_size_k80_3c .modal-wrapper,
.modal-print-print_size_k57_3c .modal-wrapper {
    @media only screen and (min-width: 768px) {
        max-width: 420px !important;
    }
}
.bill-detail  .modal-wrapper{
    // min-width: 1024px !important;
    // // height: 90%;
    width: 90%;
    height: 90%;
}
.bill-detail{
    z-index: 20 !important;
}
.modal-login .modal-wrapper {
    width: 85%;
    height: 85%;
}


/** Modal controller */